<template>
    <user-dashboard-layout>
        <v-container class="fill-height  mt-15" v-if="loading">
            <v-col class="text-center">
                <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate
                ></v-progress-circular>
            </v-col>
        </v-container>
        <v-container>
            <v-row dense>
                <v-chip-group column active-class="primary--text">
                    <v-chip
                        exact
                        v-for="(tag, index) in tags"
                        @click="$router.push({query: {tag: tag.name}})"
                        :key="index"
                        v-text="tag.name"/>
                </v-chip-group>
            </v-row>
            <v-row>
                <timeframe-select/>
            </v-row>
            <v-row dense>
                <v-col sm="12" md="6" xl="3" v-for="(item, i) in guides" :key="i">
                    <guide-card :guide="item"/>
                </v-col>
            </v-row>
        </v-container>
    </user-dashboard-layout>
</template>

<script>
    import UserDashboardLayout from "../../../layouts/UserDashboardLayout";
    import GuideCard from "../../../components/cards/GuideCard";
    import Guide from "../../../models/Guide";
    import Tag from "@/models/Tag";
    import TimeframeSelect from "@/views/partials/TimeframeSelect";

    export default {
        name: "Index",
        components: {TimeframeSelect, GuideCard, UserDashboardLayout},
        data: function () {
            return {
                loading: false,
                guides: [],
                tags: []
            }
        },
        async mounted() {
            this.loading = true
            this.tags = await Tag.where('has_guides', true).get();
            await this.getGuides();
            this.loading = false
        },
        methods: {
            async getGuides() {
                let query = Guide.where('industry_id', this.$auth.user().industry_id);

                if (this.$route.query.lastDays) {
                    query.where('lastDays', this.$route.query.lastDays);
                }

                this.guides = this.$route.query.tag
                    ? await query.where('has_tag', this.$route.query.tag).include(['tags']).get()
                    : await query.include(['tags']).get();
            },
            removeChip() {
                this.$router.replace({'query': null});
            }
        },
        watch: {
            "$route.query": async function (val, oldVal) {
                if (val !== oldVal) {
                    await this.getGuides();
                }
            }
        }
    }
</script>

<style scoped>

</style>
