<template>
    <v-card max-width="374" class="mx-auto my-12">
        <v-avatar width="100%" height="250" tile @click="$router.push({name: 'dashboard.guides.show', params: {id: guide.id}})">
            <v-img :src="guideAvatar"/>
        </v-avatar>
        <v-card-title class="pb-0 justify-space-around" v-text="guide.name"  @click="$router.push({name: 'dashboard.guides.show', params: {id: guide.id}})"/>
<!--        <v-card-text class="mt-4">
            <v-chip v-for="(file, fileIndex) in guide.files" :key="fileIndex"  @click="$router.push({name: 'dashboard.guides.show', params: {id: guide.id}})">
                {{ $t('languages.' + file.language) }}
            </v-chip>
            <div class="mt-4" v-text="guide.created_at"/>
        </v-card-text>
        <v-card-text>
            <v-chip
                v-for="(tag, tagIndex) in guide.tags"
                @click.prevent="handleTagClick(tag)"
                :key="tagIndex"
                v-text="tag.name"/>
        </v-card-text>-->
    </v-card>
</template>

<script>
    export default {
        name: "GuideCard",
        props: ['guide'],
        computed: {
            guideAvatar() {
                return this.guide.gallery_images.length ? this.guide.gallery_images[0] : ''
            }
        },
        data: function () {
            return {}
        },
        methods: {
            handleTagClick(tag) {
                this.$router.push({query: {tag: tag.name}});
            }
        }
    }
</script>

<style scoped>

</style>
